<template>
  <content-wrapper :title="labels.LBL_CLIENTS">
    <template #actions>
      <el-button size="small" type="success" @click="handleAdd">
        <i class="el-icon-plus mr-1"></i>
        {{ labels.LBL_ADD }}
      </el-button>
    </template>

    <el-table
      v-loading="loading"
      :data="clientList"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column label="Logo" width="100">
        <template slot-scope="scope">
          <el-avatar
            :src="scope.row.logo_url"
            :size="60"
            shape="square"
            fit="contain"
          >
            <img
              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
            />
          </el-avatar>
        </template>
      </el-table-column>

      <el-table-column prop="name" :label="labels.LBL_NAME" sortable>
      </el-table-column>

      <el-table-column align="right" width="300">
        <template slot="header" slot-scope="scope">
          <el-row type="flex">
            <el-input
              v-model="search"
              size="mini"
              :placeholder="labels.LBL_TYPE_2_SEARCH"
              class="ml-1"
            />
          </el-row>
        </template>

        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          >
          </el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination"
      :current-page.sync="pagination.current_page"
      :total="pagination.total"
      :page-size="parseInt(pagination.per_page)"
      layout="total, prev, pager, next"
      class="my-2"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </content-wrapper>
</template>

<script>
  import _ from "lodash";
  import { labels } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import { mapState } from "vuex";

  export default {
    name: "Companies",

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,

        search: "",
        params: {
          page: 1,
          limit: 4,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        clients: (state) => state.clients.clients,
      }),

      clientList() {
        return this.clients && this.clients.data;
      },

      pagination() {
        return this.clients && this.clients.meta;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),

      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadClients();
        },
      },
    },

    created() {
      this.loadClients();
    },

    methods: {
      loadClients() {
        this.$store.dispatch("clients/getAllClients", this.params);
      },

      handleSearch(search) {
        this.$store.dispatch("clients/getAllClients", {
          ...this.params,
          search,
        });
      },

      handlePageChange(val) {
        this.params.page = val;
      },

      handleAdd() {
        this.$router.push({ name: "Add Client" });
      },

      handleEdit({ id }) {
        this.$router.push({ name: "Edit Client", params: { clientId: id } });
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store.dispatch("clients/deleteClient", id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>

<style></style>
